.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.lightbox-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
  position: absolute;
  background: none;
  border: none;
  color: white;
  /* font-size: 2rem; */
  cursor: pointer;
  user-select: none;
}

.lightbox-close {
  top: 10px;
  right: 20px;
  font-size: 4rem;
}

.lightbox-prev {
  top: 50%;
  left: 10px;
  font-size: 6rem;
  transform: translateY(-50%);
}

.lightbox-next {
  top: 50%;
  right: 10px;
  font-size: 6rem;
  transform: translateY(-50%);
}
/* .image-bg{
  background-color: red;
  width: 50%;
  height: auto;
} */