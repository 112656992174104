/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-content {
    background: white;
    border-radius: 10px;
    position: relative;
    width: 90%;
    max-width: 700px;
    overflow-y: auto;
    max-height: 80vh;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
}
.modal-close:hover{
    color:#ff731d
}

@media (min-width: 768px) {
    .modal-content {
        width: 80%;
        /* Adjust width for medium-sized screens */
    }
}

@media (min-width: 1200px) {
    .modal-content {
        width: 70%;
        /* Adjust width for large screens */
    }
}

.add-slot {
    color: #232323;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid #232323;
    padding: 9px 27px;
}

.add-slot:hover {
    text-decoration: none;
    background: #ff731d;
    border: 2px solid #ff731d;
    color: #FFF;
}

.btn {
    float: right;
}

.border-top {
    display: flex;
    justify-content: center;
    align-items: center
}
.slot-dropdown {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 200px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.slot-dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.slot-dropdown-item:hover {
    background: #f0f0f0;
}
.extra{
    margin-top: -30px;
}

.slot-dropdown-item {
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
}

.slot-dropdown-item.disabled {
    opacity: 0.5; /* Dim the disabled slots */
    pointer-events: none; /* Prevent clicking on disabled slots */
}