html {
    scroll-behavior: auto;
    /* Ensure smooth scrolling is disabled */
}

.MuiPickersToolbar-root {
    display: none !important;

}

.MuiPickersCalendarHeader-switchViewButton {
    display: none !important;

}

.MuiPickersCalendarHeader-root {
    color: white;
    border-radius: 4px;
    border-width: 2px;
    border-color: #e91e63;
    border: 2px solid;
    background-color: #8f7037;
}

.MuiDialogActions-root {
    display: none !important;
}

.MuiPickerStaticWrapper-root {
    box-shadow: 3px 2px 10px 5px #888888 !important;
}

.MuiPickersToolbar-root-MuiDatePickerToolbar-root .MuiPickersToolbar-penIconButton {
    display: none !important;
}


.MuiDayCalendar-weekDayLabel {
    /* background-color: #977a42; */
}


.MuiTypography-root {
    color: white;
}

.MuiDayCalendar-root {
    color: #f4f4f4;
    border-radius: 5px;
    /* border-width: 4px; */
    border-color: #e91e63;
    border: 4px solid;
    background-color: white;
    box-shadow: 3px 2px 10px 5px #888888 !important;

}

.MuiPickersDay-today {
    color: black !important;
    background-color: rgb(235, 240, 143) !important;
    /* font-size: 20px; */

}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
    will-change: background-color;
    background-color: orangered !important;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    will-change: background-color;
    background-color: #8f7037 !important;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: orange !important;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root {
    font-size: 1.3rem !important;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    font-size: 1.3rem !important;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
    font-size: 1.3rem !important;

}



.datepicker-container-Home {
    position: absolute;
    z-index: 1000;
    top: 270px;
    left: 20%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 1rem;
    width: 100%;
    max-width: 348px;
    margin: 0 auto;
}

@media (max-width: 768px) {

    .datepicker-container-Home {
        left: 50%;
        top: 360px;
        max-width: 335px;
    }

    .MuiDateCalendar-root {
        /* display: none !important; */
        top: 50px;
        width: 90%;
        padding: 1rem;
        /* left: 50%; */
        /* transform: translateX(-50%); */
    }
}
/* Adjust for large screens and up */
@media (min-width: 768px) and (max-width: 992px) {
    .datepicker-container-Home {
        left: 30%;
        top: 290px;
        max-width: 450px;
    }
}

/* Adjust for extra large screens and up */
@media (min-width: 992px) and (max-width: 1200px) {
    .datepicker-container-Home {
        left: 35%;
        top: 300px;
        max-width: 500px;
    }
}

.MuiDateCalendar-root {
    border-radius: 20px;
}

.react-datepicker {
    width: 100%;
}

.react-datepicker__header {
    background-color: #007bff;
    /* Bootstrap primary color */
    color: white;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
    font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    /* Bootstrap primary color */
    color: white;
}

.react-datepicker__day--today {
    background-color: #ffc107;
    /* Bootstrap warning color */
    color: black;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    display: none;
}

.calendar-toggle-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.calendar-toggle-button img {
    display: block;
}

.adults {
    width: 27% !important;
}

.time-style {
    font-size: 27px !important;
    margin-top: 20px;
    margin-left: 20px !important;
}
.turf-style{
    font-size: 40px !important;
    top:15px !important;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 5px;
    background: transparent;
}



.find_btn {
    position: relative;
}

.find_btn.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.find_btn.disabled .text-find {
    cursor: not-allowed;
}
/* .blurred {
    filter: blur(4px);
} */

.dropdown-menu {
    text-align: center;
    left: 5px;
    min-width: 300px;
    top: 100px;
    max-height: 200px; /* Adjust based on your design */
    overflow-y: scroll;
}


