/* .header-top-sky {
    position: fixed;
    top: 0;
    width: 100%;
} */

/* .navbar-nav li a:hover {
    color: red;
} */
.header-sky .navbar .navbar-nav li:hover a {
    background: transparent;
    color: #ff731d !important;
  }